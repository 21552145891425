<!-- 面试管理 -->
<template>
  <div class="InterviewManagement" v-loading="loading">
    <div class="caozuo"></div>
    <div class="shijianzhou" v-if="interviewData.length > 0">
      <div
        v-for="(item, index) in interviewData"
        :key="index"
        class="shjijianzhou_item"
      >
        <div class="title" :style="item.title == today ? 'left:-56px' : ''">
          {{ item.title == today ? "今天" : item.title }}
        </div>
        <el-card class="box-card" shadow="always">
          <div
            v-for="(o, index) in item.children"
            :key="o.interview_time + index"
            class="card_item"
          >
            面试时间:
            <span style="font-weight: bold">{{ o.interview_time }}</span>
            面试人:
            <span>{{ o.resume_name.length > 0 ? o.resume_name : "暂无" }}</span>
          </div>
        </el-card>
      </div>
    </div>
    <div v-else class="kong">暂时还没有面试哦</div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { interview_list } from "@/api/tool";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      interviewData: [],
      pageTotal: 100,
      pageNow: 1,
      loading: true,
      today: "",
      yestoday: "",
      tomorrw: "",
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    time() {
      var date = new Date();
      let year = date.getFullYear(); //获取完整的年份(4位)
      let month = date.getMonth() + 1; //获取当前月份(0-11,0代表1月)
      let data = date.getDate(); //获取当前日(1-31)

      this.today =
        year +
        "-" +
        (month.length == 1 ? "0" + month : month) +
        "-" +
        (data.length == 1 ? "0" + data : data);
    },
    //格式化
    timefun(info) {
      let arr = [];
      info.forEach((item, index) => {
        console.log(item);
        arr.push(item.interview_time.substring(0, 10));
      });
      console.log(arr);
      for (var i = 0; i < arr.length; i++) {
        if (arr.indexOf(arr[i]) != i) {
          arr.splice(i, 1); //删除数组元素后数组长度减1后面的元素前移
          i--; //数组下标回退
        }
      }
      arr.forEach((item) => {
        this.interviewData.push({ title: item, children: [] });
      });
      info.forEach((item, index) => {
        this.interviewData[
          arr.indexOf(item.interview_time.substring(0, 10))
        ].children.push({
          interview_time: item.interview_time.substring(10),
          resume_name: item.resume_name,
        });
      });
      this.loading = false;
    },
    init(page) {
      interview_list({ page }).then((res) => {
        console.log(res);
        let info = res.data.interview_data;
        //格式化时间
        this.timefun(info);
      });
    },
    pageNowHandler(page) {
      this.pageNow = page;
      this.init(page - 1);
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.init(0);
    this.time();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style  scoped lang='scss'>
/* //@import url(); 引入公共css类 */
.InterviewManagement {
  min-height: 80vh;
  @include padding;
}
.shjijianzhou_item {
  width: 100%;
  position: relative;
  margin-bottom: 20px;
}
.title {
  position: absolute;
  line-height: 20px;
  top: 20px;
  margin-top: -10px;
  left: -105px;
  z-index: 10;
  font-weight: bold;
}
.title::before {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 48%;
  margin-top: -6px;
  right: -18px;
  border: #ff9900 solid 1px;
  border-radius: 50%;
}
.title::after {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  position: absolute;
  top: 50%;
  margin-top: -3px;
  right: -15px;
  background: #ff9900;
  border-radius: 50%;
}
// 分页
.pagination {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}
.shijianzhou {
  width: 1050px;
  border-left: #ff9900 solid 2px;
  padding-left: 12px;
  margin-left: 50px;
  // margin: 20px 0;s
}
.card_item {
  padding: 4px 0;
  font-size: 14px;
  letter-spacing: 2px;
}
.card_item span {
  font-size: 16px;

  margin-right: 20px;
}
.kong {
  text-align: center;
  color: #dcdcdc;
  font-size: 16px;
  line-height: 90px;
  background: #fff;
}
</style>